.section-container {
  height: auto;
  width: auto;
}

section {
  max-width: 1200px;
  min-height: calc(100vh - var(--layout-height) - var(--layout-height));
  scroll-margin-top: var(--layout-base-height);
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .section-flexbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  }
  .section-title {
    text-align: center;
    font-size: 3rem;
  }
  .section-left,
  .section-right  {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  .section-full-width {
    width: 100%;
    padding: 16px;
    background-color: var(--white);
  }
  img {
    max-height: 600px;
    object-fit: cover;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .section-full-width-image {
    img {
      height: 400px;
      width: 100%;
    }
  }

}

#venue {
  img {
    max-height: 400px !important;
  }
}

@media (max-width: 768px) {
  section {
    scroll-margin-top: var(--mobile-layout-height);
    min-height: 100vh;
    .section-title {
      text-align: center;
      font-size: 2rem;
    }
    .section-flexbox {
      flex-wrap: wrap;
    }
    .section-left,
    .section-right {
      width: 100%;
    }
    .section-text {
      order: 1;
    }
    .section-image,
    .section-map {
      order: 2;
    }
    img {
      height: 400px;
    }
  }
}
