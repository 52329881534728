:root {
  --switch-width: 36px;
  --switch-height: calc(var(--switch-width) * 0.625);
  --handle-width: calc(var(--switch-width) * 0.5);
  --handle-height: calc(var(--switch-height) * 0.8);
}

.switch {
  width: var(--switch-width);
  height: var(--switch-height);
  background-color: var(--pink);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &[data-ison="true"] {
    justify-content: flex-end;
  }
}

.handle {
  width: var(--handle-width);
  height: var(--handle-height);
  background-color: var(--white);
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-to-top {
  position: fixed;
  bottom: calc(var(--layout-height) * 2.5);
  right: calc(var(--layout-height));
  width: 50px;
  height: 50px;
  background-color: var(--green);
  color: var(--white);
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &:hover {
    background-color: var(--brown);
  }

  &:focus {
    outline: none;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    display: none;
  }
}
