:root {
  --progress-bar-top-position: calc(
    var(--layout-height) + var(--layout-paddding)
  );
}

.progress-bar {
  position: fixed;
  top: var(--progress-bar-top-position);
  left: 0;
  right: 0;
  height: 10px;
  background: var(--pink);
  transform-origin: 0%;
  z-index: 1100;
}
