#rsvpForm {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

fieldset {
  margin: 0 auto 8px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  box-sizing: border-box;
  width: 75%;

  legend {
    font-size: 1.5rem;
    background-color: var(--white);
  }

  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    max-width: 100%;
    padding: 8px;
    border: 1px solid var(--black);
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }

  input[type="radio"],
  input[type="checkbox"] {
    accent-color: var(--green);
  }
}

.rsvp-form-errors {
  color:red;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.rsvp-form-email-error,
.rsvp-form-success{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.rsvp-form-success {
  color: var(--green);
  margin-bottom: 0;
  padding: 8px 0px;
}

#fieldset-adult,
#fieldset-child,
#fieldset-dog {
  fieldset {
    box-shadow: none;
    border: none;
  }
}

.button-group {
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.rsvp-form-block {
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }
}


.rsvp-button,
.submit-button {
  color: var(--white);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out;
  box-shadow: none;
  margin-left: auto;
  margin-right: auto;
}

.add-guest {
  background-color: var(--green);

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
  }
}

.remove-guest {
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    color: rgba(0, 0, 0, 0.2);
    background-blend-mode: multiply;
  }
}


.submit-button {
  background-color: var(--green);

  &:hover {
    background-color: var(--pink);
  }

  &:disabled {
    background-color: var(--light-gray);
    cursor: not-allowed;
  }
}

.security-question {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  p {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  input[type="text"] {
    border-radius: 4px;
    width: 50%;
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  fieldset {
    width: 100%;
  }

  .security-question {
    input[type="text"] {
      width: 75%;
    }
  }
  .submit-button {
    width: 75%;
  }
}
