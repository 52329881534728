:root {
  --layout-line-height: 1.5rem;
  --layout-base-height: 32px;
  --layout-padding: 16px;
  --layout-height: calc(
      var(--layout-base-height)
      + var(--layout-padding)
      + var(--layout-line-height)
    );
  --small-laptop-layout-height: calc(
      calc(var(--layout-base-height) / 2)
      + var(--layout-padding)
      + var(--layout-line-height)
    );
  --mobile-layout-padding: calc(var(--layout-padding) * 0.75);
  --mobile-layout-line-height: calc(var(--layout-line-height) * 0.75);
  --mobile-layout-height: calc(
      var(--mobile-height)
      + var(--mobile-layout-padding)
      + var(--mobile-layout-line-height)
    );
  --mobile-height: calc(var(--layout-base-height) * 0.5);
  --layout-border: 1px solid rgba(0, 0, 0, 0.2);
  --layout-border-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1);
}

footer,
nav {
  height: var(--layout-height);
  background-color: var(--white);
  padding: var(--layout-padding);
  font-size: var(--layout-line-height);
  line-height: var(--layout-line-height);
  box-shadow: var(--layout-border-shadow);
  z-index: 1200;
  display: flex;
  align-items: center;
  border: var(--layout-border);
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: flex-start;
  svg {
    margin-right: 20px;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: var(--accent);
  }
}

nav {
  position: sticky;
  top: 0;
  justify-content: center;
  flex-wrap: wrap;
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    a {
      color: var(--black);
      text-decoration: none;
      margin: 0 15px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#languageToggle {
  position: fixed;
  right: 0;
  height: var(--layout-height);
  line-height: var(--layout-line-height);
  padding: var(--layout-padding);
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: default;
  p {
    margin: 0;
    font-size: 1rem;
    padding: 0 8px;
  }
  .on {
    background-color: var(--brown);
  }
  .off {
    background-color: var(--green);
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  footer,
  nav {
    height: var(--small-laptop-layout-height);
    padding: calc(var(--layout-padding) / 2);
  }
}


@media (max-width: 768px) {
  footer,
  nav {
    height: var(--mobile-layout-height);
    background-size: 4px 4px;
    backdrop-filter: blur(3px);
    padding: var(--mobile-layout-padding);
    font-size: var(--mobile-layout-line-height);
    line-height: var(--mobile-layout-line-height);
    span {
      font-size: 1rem;
    }
  }

  footer {
    svg {
      transform: scale(0.5);
    }
  }

  #languageToggle {
    height: var(--mobile-layout-height);
    padding: var(--mobile-layout-padding);
  }
}
