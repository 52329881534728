/* stylesheets */
@import "framer-motion.scss";
@import "layout.scss";
@import "buttons.scss";
@import "forms.scss";
@import "sections.scss";
@import "map.scss";
@import "leaflet/dist/leaflet.css";

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --black: #111111;
  --white: #f4f2ec;
  --green: #627a41;
  --pink: #f2bdae;
  --brown: #4f1d06;
  --sand: #e4d4b5;
  --gray: #666;
  --light-gray: #9f9f9f;

  --background: var(--white);
  --accent: var(--black);
}

p,
strong {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

strong {
  font-weight: 500;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--accent);
  padding-bottom: 100px;
}

* {
  scroll-behavior: smooth;
  font-family: "Cinzel", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  color: var(--brown);
}

h1,
h2,
h3 {
  font-family: "Cinzel", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-kerning: auto;
}

h1 {
  font-size: 36px;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
  margin: 100px 0 40px;
}

h2 {
  font-weight: 400;
  margin: 50px 0 10px;
}

p {
  margin: 0 0 30px 0;
  font-size: 18px;
}

@media (max-width: 768px) {
  body {
    padding-bottom: var(--layout-height);
  }
}
